<template>
  <v-container
    class="container"
  >
    <v-row
      class="align-center justify-center"
      no-gutters
    >
      <v-col
        class="align-center justify-center"
        cols="12"
        lg="8"
        md="10"
        sm="12"
        xl="4"
      >
        <v-card
          style="
            height: 400px;
          "
          class="d-flex my-0"
        >
          <div
            v-if="!isDeviceWidthSmall"
            style="width: 50%"
            class="d-flex justify-center"
          >
            <v-img
              src="../../assets/doctor.png"
              width="100%"
              height="100%"
            />
            <v-card
              style="
                position: absolute;
                left: 30px;
                bottom: 30px
              "
              class="pa-2"
            >
              <img
                src="https://cdn.clinic.inf.br/public/clinic/logo.svg"
                contain
                height="40px"
              >
            </v-card>
          </div>
          <div
            :style="!isDeviceWidthSmall ? 'width: 50%;' : 'width: 100%;'"
          >
            <v-tabs>
              <v-tab
                v-for="(item) in loginOptions"
                :key="item"
              >
                {{ item }}
              </v-tab>
            </v-tabs>
            <div>
              <v-container>
                <loginForm v-if="loginTab === 'Login'" />
              </v-container>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import loginForm from './LoginForm'

  export default {
    components: {
      loginForm,
    },
    data: () => ({
      windowWidth: window.innerWidth,
      loginTab: 'Login',
      loginOptions: [
        'Login',
      ],
    }),
    computed: {
      isDeviceWidthSmall: function () {
        if (this.windowWidth < 650) {
          return true
        } else return false
      },
    },
    mounted () {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    },
  }
</script>
<style scoped>
  .container {
    height: 100%;
    display: contents;
    width: 80%;
    margin: 0 auto;
  }
  ::-webkit-scrollbar{
    display: none;
  }
</style>
