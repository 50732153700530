<template>
  <v-app>
    <div class="background" />
    <login />
  </v-app>
</template>

<script>
  import login from './Login'

  export default {
    components: {
      login,
    },
  }
</script>

<style scoped>
  .background {
    background-image: url('../../assets/bg_removed.png');
    width: 100vw;
    height: 100%;
    position: absolute;
    background-size: cover;
  }

</style>
